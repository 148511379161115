import React from "react";
import Slider from "react-slick";
import "./Announcement.css";
import { CopyIcon, NextIcon, PrevIcon } from "../../assets/svg";
import AnnouncementCard from "./AnnouncementCard";
import SlideShowCard from "../SlideShow/SlideShowCard";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      // className={className}
      style={{
        position: "absolute",
        height:'25px',
        width:'25px',
        marginRight:'auto',
        marginTop:'auto',
        marginBottom:'auto',
        right:10,
        top:0,
        bottom:0,
        display: "flex",
        justifyContent:'center',
        alignItems:'center',
        zIndex: "10",
        cursor:"pointer"
      }}
      onClick={onClick}
    >
      <NextIcon height={30} width={30} color={"lightgray"}  />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      // className={className}
      style={{
        position: "absolute",
        height:'25px',
        width:'25px',
        marginLeft:'auto',
        marginTop:'auto',
        marginBottom:'auto',
        left:10,
        top:0,
        bottom:0,
        display: "flex",
        justifyContent:'center',
        alignItems:'center',
        zIndex: "10",
        cursor:"pointer"
      }}
      onClick={onClick}
    >
      <PrevIcon height={30} width={30} color={"lightgray"} />
    </div>
  );
}

function Announcement() {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplay: true,
    autoplaySpeed: 9000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div style={{  }}>
      <div className="SliderTitle" style={{marginBottom:10}}>
        {" "}
        <span
        // style={{ marginBottom: "5px", marginTop: "5px", minHeight: "5vh"}}
        >
          Duyurular
        </span>
      </div>
      <Slider {...settings} className="CourseSliderContainer">
        {/* <CourseSlideCard
          url={"https://kubbealti.org.tr/kurs/uzaktan-egitim-farsca-baslangic-seviyesi"}
          img={require("../../assets/img/fars.png")}
          name={1}
        /> */}
        <AnnouncementCard
          url={"http://emineisinsu.com/"}
          img={require("../../assets/img/emine_isinsu.jpg")}
          name={1}
        />
        <SlideShowCard
          url={"https://kubbealti.org.tr/kitap/deftername"}
          img={require("../../assets/img/deftername.jpeg")}
          name={2}
        />    
        <SlideShowCard
          url={"https://kubbealti.org.tr/kitap/muzehhip-muhsin-demironat"}
          img={require("../../assets/img/müzehhip_large.jpeg")}
          name={3}
        />
        {/* <SlideShowCard url={"https://kubbealti.org.tr/kitap/yetmisinden-sonra"} img={require("../../assets/img/yetmişindenSonra.jpeg")} name={2}/> */}
      </Slider>
    </div>
  );
}

export default Announcement;
